<template>
  <div class="user-logged">
    <div class="balance">
      {{ $formatAmountUnit(store.currentUser.balance, 1) }}
      <CommonBaseImg src="/assets/images/icon/coin-k.svg" class="icon-coin" alt="icon-coin" />
    </div>
    <BaseButton
      id="header-deposit-btn"
      class="btn-deposit base-button--btn-secondary"
      @click="onNavDeposit()"
    >
      Nạp
    </BaseButton>
    <div class="group-avatar" @click="openMenuAccount()">
      <BaseImg
        class="avatar"
        alt="user avatar"
        :src="`/assets/images/components/desktop/avatar/${store.currentUser?.avatar}.webp`"
      />
      <BaseImg
        class="hamburger"
        alt="hamburger"
        :src="`${PATH_AVATAR_URL}hamburger.svg`"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { PATH_AVATAR_URL } from '~/constants/path'
import { useAccountStore } from '~~/store/account'
import { useToggleMenu } from '~/composables/useToggleMenu'
import BaseButton from '~/components/common/base-button.vue'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { $pinia, $formatAmountUnit } = useNuxtApp()
const store = useAppStore($pinia)
const { setDepositFrom } = store
const accountStore = useAccountStore()
const { toggleMenuAccount, toggleMenuDeposit } = useToggleMenu()

const onNavDeposit = () => {
  setDepositFrom('deposit')
  toggleMenuDeposit()
}

const openMenuAccount = () => {
  toggleMenuAccount()
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/user-logged.scss" />
